import { css } from "@emotion/core";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@marinda/react-styled-buttons";

const componentCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const buttonCSS = theme => css`
  text-decoration: none;
  text-transform: capitalize;
  margin: 0 8px;
  color: ${theme.colors.background};
  background: ${theme.colors.muted};
  margin-bottom: 10px;
  border-radius: 10px;

  &:hover {
    color: ${theme.colors.background};
    background: ${theme.colors.primary};
  }

  &.active {
    color: ${theme.colors.background};
    background: ${theme.colors.primary};
  }

  &:focus {
    box-shadow: 0 0px 8px ${theme.colors.primary},
      0 0px 8px ${theme.colors.primary};
  }
`;

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Button css={theme => buttonCSS(theme)} size="small" activeClassName="active" onClick={() => logout({ returnTo: window.location.origin })}>
      Ausloggen
    </Button>
  );
};

export default LogoutButton;